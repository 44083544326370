import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useLocation } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import '../styles/FaqPage-style.css';
import { useTranslation } from 'react-i18next';
import { appInsights } from '../utils/ApplicationInsightsService';

// import faqJson from "./DVRQAContent.json";
import faqEn from './faq-locales/faq-en.json';
import faqEs from './faq-locales/faq-es.json';
import faqCn from './faq-locales/faq-cn.json';
import faqKr from './faq-locales/faq-kr.json';
import faqPh from './faq-locales/faq-ph.json';
import faqTw from './faq-locales/faq-tw.json';
import faqVi from './faq-locales/faq-vi.json';
import faqAe from './faq-locales/faq-ae.json';
import SearchBar from './SearchBar';
import MobileSearchBar from './MobileSearchBar';

/* eslint-disable no-unused-vars */

function SearchPage({ _setHtmlDir, _htmlDir, settings }) {
  const [mobileTabDisplay, setMobileTabDisplay] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchedFaq, setSearchedFaq] = useState();
  const { _t, i18n } = useTranslation();

  const { search } = useLocation();

  const faqJsonMap = {
    en: faqEn,
    es: faqEs,
    cn: faqCn,
    kr: faqKr,
    ph: faqPh,
    tw: faqTw,
    vi: faqVi,
    ae: faqAe,
  };


  // Get the current language
  // const currentLanguage = i18n.language;
  
  // Current search page implementation is only in english
  const currentLanguage = 'en';

  // Only show max 10 search results
  const maxResults = 5;

  

  useEffect(() => {    
    appInsights.trackEvent({ name: `Loaded search page` });

    const match = search.match(/tab=(.*)/);
    const tab = match?.[1];

    const searchParams = new URLSearchParams(location.search);
    setSearchQuery(searchParams.get('query'));
    setSearchedFaq(getSearchedFaq())

    if (tab) {
      setTabIndex(Number(tab));
      window.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }
  }, []);

  useEffect(() => {
    setVATLinks();
  }, [settings]);

  useEffect(() => {
    setSearchedFaq(getSearchedFaq())
  }, [searchQuery]);

  useEffect(() => {
    setVATLinks();
  });

  function removePunctuationFromString(text) {

    // the faq pages have some html, punctuation, we need to remove them when counting for words
    text = text.replace(/(<([^>]+)>)/gi, " ");
    text = text.replace(".", "");
    text = text.replace(",", "");
    text = text.replace("\"", "");
    text = text.replace(")", "");
    text = text.replace("(", "");
    text = text.replace("-", " ");
    text = text.replace(";", "");
    text = text.replace(":", "");
    text = text.replace("?", "");
    return text;
  }

  function getSearchedFaq() {
    // Get the appropriate FAQ JSON based on the current language
    const faqJson = faqJsonMap[currentLanguage] || faqEn;
    let faqSearchJson = faqJson['vaccineFaq'][0]['list'].concat(faqJson['vaccineFaq'][1]['list']).concat(faqJson['vaccineFaq'][2]['list'])

    if(!searchQuery) {
        return
    }

    appInsights.trackEvent({ name: `Searching for - ${searchQuery}` });

    // if there is a direct question match, dont show anything else
    let directMatch = findDirectMatch(faqSearchJson, searchQuery);
    if (directMatch) {
        return directMatch;
    }

    let result = filterSearchResults(faqSearchJson, searchQuery);
    return result;
  }

  function findDirectMatch(faqSearchJson, input) {

    input = removePunctuationFromString(input)

    // for each faq
    for (let i = 0; i < faqSearchJson.length; i++) {

        let question = faqSearchJson[i].question;
        
        question = removePunctuationFromString(question)

        if(question.toLowerCase() == input.toLowerCase()) {
            return [faqSearchJson[i]];
        }
    }

    return null;
  }

  function filterSearchResults(faqSearchJson, input) {
    const inputArray = input.match(/\b\w+\b/g);
    
    // if any characters are NOT latin (japanese, etc) we will just return
    const isLatin = /^[\u0000-\u00FF]*$/;

    if (!inputArray || !isLatin.test(input)) {
        return;
    }

    const faqEntries = Object.entries(faqSearchJson)

    let numEntriesWithMatches = 0;

    // for each word in the search
    for (let i = 0; i < inputArray.length; i++) {

        // for each faq
        for (const [key, value] of faqEntries) {
            // count the occurances of current word within each question and answer
            let count1 = getWordOccurencesInString(value.question, inputArray[i])
            let count2 = getWordOccurencesInString(value.answer, inputArray[i])

            let totalCount = count1 + count2;

            // value.words is used for debugging. When the sorted faq is logged,
            // we can see the counts of each word
            if (i == 0) {
                value.count = totalCount
                value.words = inputArray[i] + ": " + totalCount + " "
            }
            else {
                value.count +=totalCount
                value.words += inputArray[i] + ": " + totalCount + " "
            }

            // we only show faqs with matches, so on the last word, count how many had matches
            if(i == inputArray.length - 1 && value.count > 0) {
                numEntriesWithMatches++;
            }

        }
    }

    
    let sortedFaq = [...faqSearchJson].sort((a,b) => a.count <= b.count ? 1 : -1)
    sortedFaq = sortedFaq.slice(0, Math.min(maxResults, numEntriesWithMatches))
    return sortedFaq
  }

  function getWordOccurencesInString(sentence, target) {

    sentence = removePunctuationFromString(sentence);

    let words = sentence.split(" ")

    let count = 0;
    for (let i = 0; i < words.length; i++) {
        if (words[i].toLowerCase() == target.toLowerCase()) {
            count++;
        }
    }
    return count;
  }

  function setVATLinks() {
    const elements = document.getElementsByClassName('VirtualAssistantLink');
    for (let i = 0; i < elements.length; i++) {
      elements[i].href = settings.VATLink;
    }
  }

  return (
    <div>
        <SearchBar setSearchQuery={setSearchQuery}/>
        <main className="main" id="main-content">
        
            <div
            className={
                settings.MaintenanceBannerClosed
                ? 'cookieCrumb'
                : 'cookieCrumbBanner'
            }
            >
                <ol className="breadcrumb">
                    <li>
                    <a href="/">Vaccine Record Home</a>
                    </li>
                    <li>Q&A</li>
                </ol>
            </div>
            
            <div className="SearchContainer">
                <span>
                    <MobileSearchBar setSearchQuery={setSearchQuery}/>
                </span>
                <div className="infoBoxTitle">Search Results</div>
                    <div className="desktopTabs">
                        <Tabs>
                            <TabList aria-label="Q&A Tabs">
                                <Tab
                                lang="en-us"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                >
                                <div>Search</div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>

                {searchedFaq && searchedFaq.length > 0 ? searchedFaq.map((faqs) => (
                <div>
                    <div className="searchFaqWrapper">
                        <div className="faqItem">
                            <div
                                className="questionWrapper"
                                tabIndex={0}
                            >
                                <p style={{ fontWeight: 'bold' }}>
                                    {faqs.question} 
                                </p>
                            </div>
                            <div
                            className="answer"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: faqs.answer,
                            }}
                            />
                        </div>
                    </div>
                </div>
            )) : (<div className='answer'>
                <p>Sorry, no results found for the search terms you entered.</p>
                <p style={{fontWeight:'bold'}}>Need help or have other questions?</p>
                <p><a href={settings.VATLink}>Submit a request to the Virtual Assistant</a></p>
                <p>Email the CAIR Help Desk: <a href="mailto:CAIRHelpdesk@cdph.ca.gov"> CAIRHelpdesk@cdph.ca.gov</a></p>
            </div>)}
        </div>
        <hr style={{marginTop:'200px', marginBottom:'25px'}}/>
    </main>
</div>
)
}

export default SearchPage;
